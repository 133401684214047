<style scoped></style>

<template>
  <div class="patient-form">
    <fm-form label-alone label-align="left" :inline="false">
      <fm-form-item label="名称">
        <fm-input-new v-model="newData.pname" placeholder="名称"></fm-input-new>
      </fm-form-item>
      <fm-form-item label="类型">
        <fm-select placeholder="类型" clearable filterable v-model="newData.sourceType">
          <fm-option v-for="item in types" :key="item" :label="item" :value="item"></fm-option>
        </fm-select>
      </fm-form-item>
      <fm-form-item label="功效">
        <fm-input-new type="textarea" v-model="newData.effect" placeholder="功效"></fm-input-new>
      </fm-form-item>
      <fm-form-item label="症状">
        <fm-input-new type="textarea" v-model="newData.treatment" placeholder="症状"></fm-input-new>
      </fm-form-item>
    </fm-form>
    <div style="display: flex;justify-content: center;height:50px;align-items: flex-end;">
      <fm-btn long @click="$emit('submit', newData)" style="margin-right: 2rem;">确定</fm-btn>
      <fm-btn long @click="$emit('close')">取消</fm-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: { type: Object, default: null }
  },
  data () {
    return {
      types: ['平台共享', '厂商共享方', '经典', '膏方', '诊所共享方'],
      newData: this.data ? JSON.parse(JSON.stringify(this.data)) : {}
    }
  },
  watch: {
    data: {
      deep: true,
      handler () {
        this.newData = this.data ? JSON.parse(JSON.stringify(this.data)) : {}
      }
    }
  }
}
</script>
