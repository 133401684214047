<template>
  <div v-loadingx="loading">
    <TypeTabs style="position: relative;"
      :type-list="typeList"
      v-model="type"
      @change="typeChange">
      <div style="position: absolute;right: 5px;top: 5px;display: flex;align-items: center;">
        <fm-input-new placeholder="请输入" v-model="search.value">
          <fm-select slot="prepend" style="width: 90px;" v-model="search.key">
            <fm-option label="名称" value="name"></fm-option>
            <fm-option label="症状" value="effect"></fm-option>
            <fm-option label="功效" value="treatment"></fm-option>
          </fm-select>
        </fm-input-new>
        <fm-btn style="margin-left: 10px;" @click="onSearch">搜索</fm-btn>
        <fm-btn @click="add">新增</fm-btn>
      </div>
    </TypeTabs>
    <List @chooseTmp="chooseTmp" @dataUpdate="loadData" ref="pList" style="width:100%;height:calc(100% - 98px);" :isUse="isUse" :autoHeight="autoHeight" :data-list="dataList"></List>
    <div style="display: flex;justify-content: flex-end;margin-top: 10px;">
      <fm-page
        :total="total"
        :current="pageNum"
        :pageSize="pageSize"
        @change="loadData"
        showTotal></fm-page>
    </div>
  </div>
</template>

<script>
import TypeTabs from './typeTabs'
import List from './list'

import {
  prescriptionTmpRequest
} from '@/api'

export default {
  components: {
    TypeTabs,
    List
  },
  props: {
    autoHeight: {
      type: Boolean,
      default: true
    },
    isUse: {
      type: Boolean,
      default: false
    }
  },
  created() {
    this.loadData()
  },
  data () {
    return {
      search: {
        key: 'name',
        value: null
      },
      loading: false,
      pageSize: 20,
      pageNum: 1,
      total: 0,
      type: '厂商共享方',
      typeList: [{
        key: '厂商共享方',
        label: '厂商共享方',
        parm: {
          sourceType: '厂商共享方'
        }
      },
      {
        key: '经典',
        label: '经典',
        parm: {
          sourceType: '经典'
        }
      },
      {
        key: '膏方',
        label: '膏方',
        parm: {
          sourceType: '膏方'
        }
      },
      {
        key: '诊所共享方',
        label: '诊所共享方',
        parm: {
          sourceType: '诊所共享方'
        }
      },
      {
        key: '平台共享',
        label: '平台共享',
        parm: {
          sourceType: '平台共享'
        }
      }],
      dataList: []
    }
  },
  methods: {
    add () {
      this.$refs.pList.chooseData = null
      this.$refs.pList.modalForm = true
    },
    chooseTmp (parm) {
      this.$emit('chooseTmp', parm)
    },
    onSearch () {
      this.pageNum = 1
      this.loadData()
    },
    async loadData (page) {
      this.pageNum = page || this.pageNum
      this.loading = true
      let parm = {
        pageSize: this.pageSize,
        pageNum: this.pageNum,
        searchText: this.search.value
      }
      let type = this.typeList.find(v => v.key == this.type)
      parm = Object.assign(parm, (type ? type.parm : {}))
      let data = await prescriptionTmpRequest.get(parm)
      this.dataList = data.data
      this.total = data.total
      this.loading = false
    },
    typeChange (value) {
      // this.pageNum = 1
      this.type = value
      this.loadData()
    }
  }
}
</script>