<template>
  <div>
    <fm-table-new
      :columns="columnList"
      :auto-height="autoHeight"
      v-loadingx="loading"
      border="row"
      ref="table"
      :data-list="dataList"
      :stripe="false"
      size="norm"
      emptyPlaceholder="-">
      <table-actions slot="actions" @table-action="tableAction" slot-scope="{ row }" :data="tableActions" :row="row"></table-actions>
      <div slot="empty" style="margin: 30px 0;">
        <img src="/static/images/null.png" style="width: 40px;"/>
        <div>暂无数据</div>
      </div>
    </fm-table-new>
    <fm-modal title="药品明细" v-model="modal" width="1000px">
      <detail-list @dataUpdate="dataUpdate" :tmpId="chooseData && chooseData.id ? chooseData.id : null" :canEdit="!isUse" :autoHeight="false" :data-list="chooseData && chooseData.medicineData ? chooseData.medicineData : []" v-if="chooseData && chooseData.id && modal"></detail-list>
    </fm-modal>
    <fm-modal :mask-closable="false" title="处方" v-model="modalForm" width="700px">
      <PreForm :data="chooseData" @close="modalForm = false" @submit="submit"></PreForm>
    </fm-modal>
  </div>
</template>

<script>
import TableActions from '@/components/base/TableActions'

import DetailList from './detailList'
import PreForm from './form'

import {
  prescriptionTmpRequest
} from '@/api'

export default {
  components: {
    TableActions,
    DetailList,
    PreForm
  },
  props: {
    dataList: {
      type: Array
    },
    autoHeight: {
      type: Boolean,
      default: true
    },
    isUse: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false,
      chooseData: null,
      modal: false,
      modalForm: false
    }
  },
  computed: {
    tableActions () {
      let data = [{
        key: 'detail',
        label: '明细'
      },
      {
        key: 'edit',
        label: '修改'
      },
      {
        key: 'del',
        label: '删除'
      }]
      if (this.isUse) {
        data = [{
          key: 'use',
          label: '调用'
        },
        {
          key: 'detail',
          label: '明细'
        }]
      }
      return data
    },
    columnList: {
      get () {
        let data = [{
          title: '名称',
          field: 'pname',
          fixed: 'left',
          sort: true
        },
        {
          title: '功效',
          field: 'effect'
        },
        {
          title: '症状',
          field: 'treatment',
          sort: true
        },
        {
          title: '操作',
          slot: 'actions',
          // width: 100,
          fixed: 'right',
          search: false,
          export: false,
          configurable: false
        }]
        return data
      }
    }
  },
  methods: {
    async dataUpdate () {
      this.$emit('dataUpdate')
      let parm = {
        pageSize: 1,
        pageNum: 1,
        id: this.chooseData.id
      }
      let datas = await prescriptionTmpRequest.get(parm)
      this.chooseData = datas.data[0]
    },
    async submit (data) {
      this.modalForm = false
      if (data.id) {
        await prescriptionTmpRequest.update(data.id, data)
      } else {
        await prescriptionTmpRequest.add(data)
      }
      this.$emit('dataUpdate')
    },
    async tableAction (parm, vm) {
      this.pageVm = vm
      this.chooseData = parm.data
      let action = parm.action
      if (action === 'detail') {
        this.modal = true
      } else if (action === 'use') {
        if (this.chooseData.medicineData.find(v => !v.medicineTypeId)) {
          this.$notice.warning({ title: '系统提示', desc: '处方部分药品在系统中无匹配药品，无法调用' })
          return
        }
        this.$emit('chooseTmp', this.chooseData)
      } else if (action === 'edit') {
        this.modalForm = true
      } else if (action === 'del') {
        this.delData()
      }
    },
    async delData () {
      const result = await this.$dialog.confirm({title: '系统提示', content: '确定处方' + this.chooseData.pname + '吗?'})
      if (result) {
        prescriptionTmpRequest.del(this.chooseData.id).then(() => {
          this.$notice.warning({ title: '系统提示', desc: '处方已删除' })
          this.$emit('dataUpdate')
        })
      }
    }
  }
}
</script>

<style scoped lang="less">
</style>
