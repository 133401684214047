<template>
  <div>
    <fm-table-new
      :simple-filter="true"
      :columns="columnList"
      :auto-height="autoHeight"
      v-loadingx="loading"
      border="row"
      ref="table"
      :data-list="dataList"
      :stripe="false"
      size="norm"
      emptyPlaceholder="-">
      <table-actions slot="actions" @table-action="tableAction" slot-scope="{ row }" :data="tableActions" :row="row"></table-actions>
      <div slot="empty" style="margin: 30px 0;">
        <img src="/static/images/null.png" style="width: 40px;"/>
        <div>暂无数据</div>
      </div>
    </fm-table-new>
    <fm-modal :mask-closable="false" title="处方" v-model="modal" width="700px">
      <DetailForm :data="chooseData" @close="modal = false" @submit="submit"></DetailForm>
    </fm-modal>
    <div style="position: absolute;top: 7px;right: 13px;" v-if="canEdit">
      <fm-btn @click="chooseData = null;modal = true;" style="margin-right: 2rem;">新增</fm-btn>
    </div>
  </div>
</template>

<script>
import TableActions from '@/components/base/TableActions'
import DetailForm from './formDetail'

import {
  prescriptionTmpRequest
} from '@/api'

export default {
  components: {
    TableActions,
    DetailForm
  },
  props: {
    dataList: {
      type: Array
    },
    tmpId: {
      type: Number
    },
    autoHeight: {
      type: Boolean,
      default: true
    },
    canEdit: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      loading: false,
      chooseData: null,
      modal: false
    }
  },
  computed: {
    tableActions () {
      let data = [{
        key: 'edit',
        label: '修改'
      },
      {
        key: 'del',
        label: '删除'
      }]
      if (!this.canEdit) {
        data = []
      }
      return data
    },
    columnList: {
      get () {
        let data = [{
          title: '药品种类',
          field: 'medicineTypeName',
          sort: true
        },
        {
          title: '使用量',
          field: 'useOnce',
          sort: true
        },
        {
          title: '单位',
          field: 'unit',
          sort: true
        },
        {
          title: '操作',
          slot: 'actions',
          // width: 100,
          fixed: 'right',
          search: false,
          export: false,
          configurable: false
        }]
        if (this.tableActions.length === 0) {
          data = data.slice(0, data.length - 1)
        }
        return data
      }
    }
  },
  methods: {
    async submit (data) {
      this.modalForm = false
      data.prescriptionTmpId = this.tmpId
      if (data.id) {
        await prescriptionTmpRequest.updateDetail(data.id, data)
      } else {
        await prescriptionTmpRequest.addDetail(data)
      }
      this.modal = false
      this.$emit('dataUpdate')
    },
    async tableAction (parm, vm) {
      this.pageVm = vm
      this.chooseData = parm.data
      let action = parm.action
      if (action === 'edit') {
        this.modal = true
      } else if (action === 'del') {
        this.delData()
      }
    },
    async delData () {
      const result = await this.$dialog.confirm({title: '系统提示', content: '确定删除处方药品' + this.chooseData.medicineName + '吗?'})
      if (result) {
        prescriptionTmpRequest.delDetail(this.chooseData.id).then(() => {
          this.$notify({
            title: '系统提示',
            message: '处方药品已删除',
            type: 'info'
          })
          this.$emit('dataUpdate')
        })
      }
    }
  }
}
</script>

<style scoped lang="less">
</style>
